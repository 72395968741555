import React, { Component } from "react";
import axios from "axios";
import { serverLink } from "../../../resources/url";
import swal from "sweetalert";
import PhotoForm from "./photoform";
import Header from "../../Header/header";
import { accessControl } from "../../../permission/permission";
import { connect } from "react-redux";

class AddGallery extends Component
{
    constructor()
    {
        super();
        accessControl([1, 4]);
        this.state = {
            isLoading: "off",
            imagefile: "",
            htmlelement: "",
            formData: {
                category: "",
                SubCategoryID: "",
                ImageTitle: "",
                FacultyCode: "",
                image: "",
                photo: ""
            },
            categoryList: [],
            subCategoryList: [],
        }
    }

    componentDidMount()
    {
        this.fetchGalleryCategoryList();
    }

    handleSubmit = (e) =>
    {
        e.preventDefault();
        this.setState({
            isLoading: "on",
        });
        this.onAddPhoto();
    };

    async fetchGalleryCategoryList()
    {
        await fetch(`${serverLink}gallery/category/list`)
            .then(res => res.json())
            .then(data =>
            {
                this.setState({
                    categoryList: data,
                    isLoading: false,
                });
            });
    }

    async fetchGallerySubCategoryList(categoryID)
    {
        await fetch(`${serverLink}gallery/subcategory/list/${categoryID}`)
            .then(res => res.json())
            .then(data =>
            {
                this.setState({
                    subCategoryList: data,
                    isLoading: false,
                });
            });
    }


    async onAddPhoto()
    {
        let sendData = {
            ...this.state.formData,
            InsertedBy: `${this.props.loginDetails[0].FirstName} ${this.props.loginDetails[0].MiddleName} ${this.props.loginDetails[0].Surname}`,
        };

        if (this.state.formData.category === "")
        {
            this.setState({
                isLoading: false,
            });
            this.showAlert("FORM VALIDATION ERROR", "Please select category", "error")
            return false;
        }

        if (this.state.formData.SubCategoryID === "")
        {
            this.setState({
                isLoading: false,
            });
            this.showAlert("FORM VALIDATION ERROR", "Please select sub category", "error")
            return false;
        }

        if (this.state.formData.imagefile === "")
        {
            this.setState({
                isLoading: false,
            });
            this.showAlert("FORM VALIDATION ERROR", "Please select image", "error")
            return false;
        }

        if (this.state.formData.ImageTitle === "")
        {
            this.setState({
                isLoading: false,
            });
            this.showAlert("FORM VALIDATION ERROR", "Please enter image title", "error")
            return false;
        }

        axios.post(`${serverLink}gallery/add`, sendData)
            .then(result =>
            {
                this.setState({
                    isLoading: false,
                });
                const response = result.data.message;
                if (response === "name exist")
                {
                    this.showAlert("Oops!", "gallery with the same name and title already exist", "error")
                    return false;
                } else if (response === "failed")
                {
                    this.showAlert("Oops!", "Something went wrong adding the gallery. Please try again!", "error")
                    return false;
                } else if (response === "success")
                {

                    if (this.state.imagefile !== "")
                    {
                        const formData = new FormData();
                        formData.append('photo', this.state.imagefile);
                        formData.append('entry_id', result.data.entry_id)
                        axios.patch(`${serverLink}gallery/uploadGalleryPhoto`, formData)
                            .then(result =>
                            {
                                this.setState({
                                    isLoading: false,
                                });
                                if (result.data.message === 'uploaded')
                                {
                                    this.showSuccessAlert("SUCCESS", "GALLERY ADDED SUCCESSFULLY", "success")
                                    return false;
                                } else
                                {
                                    this.showSuccessAlert("ERROR", "GALLERY UPLOAD FAILED", "error")
                                    return false;
                                }

                            })
                            .catch(err =>
                            {
                                this.setState({
                                    isLoading: false,
                                });
                                console.error('ERROR', err);
                            });
                    }
                    else
                    {
                        this.showSuccessAlert("SUCCESS", "GALLERY ADDED SUCCESSFULLY", "success")
                        return false;
                    }
                } else
                {
                    this.showAlert("Oops!", "Failed to add the gallery details!", "error")
                    return false;
                }

            })
            .catch(err =>
            {
                this.setState({
                    isLoading: false,
                });
                console.error('ERROR', err);
            });
    }

    onEdit = (e) =>
    {
        const formData = this.state.formData;

        if (e.target.name === 'category')
        {
            let index = e.nativeEvent.target.selectedIndex;
            const subSubCategoryID = e.nativeEvent.target[index].getAttribute('data');
            this.fetchGallerySubCategoryList(subSubCategoryID);

            this.setState({
                formData: {
                    ...formData,
                    [e.target.name]: e.target.value,
                },
            });

        }
        this.setState({
            formData: {
                ...formData,
                [e.target.name]: e.target.value,
            },
        });
    };

    // onImageChange = (event) => {
    //     const formData = this.state.formData;
    //     this.setState({formData: {
    //             ...formData, photo: event
    //         },});
    // };

    onImageChange = (event) =>
    {
        if (event.target.files && event.target.files[0])
        {
            const file = event.target.files[0]
            if (file.type === "image/png" || file.type === "image/jpg" || file.type === "image/jpeg")
            {
            } else
            {
                this.showAlert("Oops!", "Only .png, .jpg and .jpeg format allowed!", "error")
                return false;
            }
            if (file.size > 5000000)
            {
                this.showAlert("Oops!", "max file size is 5mb", "error")
                return false;
            }

            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = (e) =>
            {
                this.setState({
                    imagefile: event.target.files[0],
                    htmlelement: e.target.result,
                    image_name: file.name,
                    view: file.mozFullPath,
                });
            };
        }
    };

    showSuccessAlert(title, msg, type)
    {
        return swal({
            title: title,
            text: msg,
            icon: type,
            button: "OK",
        }).then((value) =>
        {
            window.location.href = '/about/gallery/list';
        });
    }

    showAlert(title, msg, type)
    {
        return swal({
            title: title,
            text: msg,
            icon: type,
            button: "OK",
        });
    }
    render()
    {
        return (
            <><Header isFaculty={false} />
                <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                    <div className="toolbar" id="kt_toolbar">
                        <div id="kt_toolbar_container" className="container-fluid d-flex flex-stack">
                            <div data-kt-swapper="true" data-kt-swapper-mode="prepend" data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}" className="page-title d-flex align-items-center flex-wrap me-3 mb-5 mb-lg-0">
                                <h1 className="d-flex align-items-center text-dark fw-bolder fs-3 my-1">About
                                    <span className="h-20px border-dark  border-start ms-3 mx-2" />
                                    GALLERY
                                    <span className="h-20px border-dark  border-start ms-3 mx-2" />
                                    ADD GALLERY
                                </h1>
                            </div>
                        </div>
                    </div>
                    <div className="post d-flex flex-column-fluid" id="kt_post">
                        <div id="kt_content_container" className="container-fluid">
                            {/* CONTENT START */}
                            <div className="card mb-5 mb-xl-10">
                                <div className="card-header">
                                    <div className="card-title">
                                        <h3>ADD GALLERY</h3>
                                    </div>
                                </div>
                                <PhotoForm
                                    handleSubmit={this.handleSubmit}
                                    onEdit={this.onEdit}
                                    onImageChange={this.onImageChange}
                                    isLoading={this.state.isLoading}
                                    data={this.state.formData}
                                    categoryList={this.state.categoryList}
                                    subCategoryList={this.state.subCategoryList}
                                    image={this.state.htmlelement}
                                />

                            </div>
                            {/* CONTENT END */}
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

const mapStateToProps = (state) =>
{
    return {
        loginDetails: state.loginDetails,
    };
};
export default connect(mapStateToProps, null)(AddGallery);
