import React, { Component } from "react";
import { EditorState, convertToRaw } from 'draft-js';
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from 'draftjs-to-html';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { stateFromHTML } from "draft-js-import-html";
import SimpleFileUpload from "react-simple-file-upload";
import { projectName, projectUploadAPI } from "../../resources/url";

class EventForm extends Component
{
    constructor(props)
    {
        super(props);
        const contentBlocks = stateFromHTML(this.props.data.Description);
        this.state = {
            editorState: EditorState.createWithContent(contentBlocks !== "" ? contentBlocks : ''),
        };
    }

    formatDate(date)
    {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    }

    onEditorStateChange = editorState =>
    {
        this.setState({
            editorState,
        });
        this.props.data.Description = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    };
    render()
    {
        const { editorState } = this.state;
        return (
            <form onSubmit={this.props.handleSubmit}>
                <div className="card-body" style={{ textAlign: 'left' }}>
                    <div className="scroll-y me-n7 pe-7" id="kt_modal_new_address_scroll text-left" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_new_address_header" data-kt-scroll-wrappers="#kt_modal_new_address_scroll" data-kt-scroll-offset="300px">
                        <div className="row mb-5">
                            <div className="col-md-12 fv-row">
                                <label className="required fs-5 fw-bold mb-2">Event Title</label>
                                <input
                                    type="text"
                                    className="form-control form-control-solid  mb-5"
                                    name="Title"
                                    id="Title"
                                    onChange={this.props.onEdit}
                                    value={this.props.data.Title}
                                    placeholder="Event Title"
                                />
                            </div>

                        </div>
                        <div className="row mb-5">
                            <div className="col-md-6 fv-row">
                                <label className="required fs-5 fw-bold mb-2">Event Date</label>
                                <input
                                    type="date"
                                    className="form-control form-control-solid  mb-5"
                                    name="EventDate"
                                    id="EventDate"
                                    onChange={this.props.onEdit}
                                    value={this.formatDate(this.props.data.EventDate)}
                                    placeholder="Event Date"
                                />
                            </div>
                            <div className="col-md-6 fv-row">
                                <label className="required fs-5 fw-bold mb-2">Location</label>
                                <input
                                    type="text"
                                    className="form-control form-control-solid  mb-5"
                                    name="Location"
                                    id="Location"
                                    onChange={this.props.onEdit}
                                    value={this.props.data.Location}
                                    placeholder="Event Location"
                                />
                            </div>

                        </div>

                        <div className="row mb-5">
                            <div className="col-md-4 fv-row">
                                <label className="required fs-5 fw-bold mb-2">Start Time</label>
                                <input
                                    type="time"
                                    className="form-control form-control-solid  mb-5"
                                    name="StartTime"
                                    id="StartTime"
                                    onChange={this.props.onEdit}
                                    value={this.props.data.StartTime}
                                    placeholder="Start Time"
                                />
                            </div>
                            <div className="col-md-4 fv-row">
                                <label className="required fs-5 fw-bold mb-2">End Time</label>
                                <input
                                    type="time"
                                    className="form-control form-control-solid  mb-5"
                                    name="EndTime"
                                    id="EndTime"
                                    onChange={this.props.onEdit}
                                    value={this.props.data.EndTime}
                                    placeholder="End Time"
                                />
                            </div>
                            <div className="col-md-4 fv-row">
                                <label className="required fs-5 fw-bold mb-2">Status</label>
                                <select className="form-control" id="Status" name="Status" onChange={this.props.onEdit} value={this.props.data.Status}>
                                    <option>-select status-</option>
                                    <option value={"0"}>Draft</option>
                                    <option value={"1"}>Publish</option>
                                </select>
                            </div>
                        </div>

                        <div className="row mb-5">
                            <div className="col-md-6 fv-row">
                                <label className=" fs-5 fw-bold mb-2">Image</label>
                                {/* <SimpleFileUpload
                                    apiKey={projectUploadAPI}
                                    onSuccess={this.props.onImageChange}
                                    tag={`${projectName}-event`}
                                    accepted="image/*"
                                    maxFileSize={1}
                                    preview="true"
                                    width="100%"
                                />
                                <small className="text-danger"><strong>File must not exceed 1MB</strong></small> */}
                                {/*<input*/}
                                {/*    type="file"*/}
                                {/*    className="form-control form-control-solid  mb-5"*/}
                                {/*    name="photo"*/}
                                {/*    id="photo"*/}
                                {/*    onChange={this.props.onImageChange}*/}
                                {/*    accept="image/*"*/}
                                {/*/>*/}

                                <div className="mb-3">
                                    <div className="fv-row mb-2">
                                        <div className="dropzone" id="kt_ecommerce_add_product_media" onClick={() =>
                                        {
                                            document.getElementById("photo").click()
                                        }}>
                                            <div className="dz-message needsclick">
                                                <i className="bi bi-image text-primary fs-3x" />
                                                <div className="ms-4 col-md-9">
                                                    <h3 className="fs-5 fw-bold text-gray-900 mb-1">Click to upload.</h3>
                                                    <span className="fs-7 fw-semibold text-gray-400 text-info"> {this.props.data.image_name !== "" ? this.props.data.image_name : "Only .jpg, .png, .jpeg are allowed"}</span>
                                                </div>
                                                <div className="col-md-2">{this.props.image !== "" ? <img className="img-thumbnail" width={120} height={100} srcSet={this.props.image !== "" ? this.props.image : ""} /> : ""}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <span className="alert-danger"> Max of 2MB file is allowed!</span>
                                </div>
                                <input type="file" id="photo" name={"photo"} accept={"image/*"} onChange={this.props.onImageChange} hidden />

                            </div>
                        </div>

                        <div className="d-flex flex-column mb-9 fv-row">
                            <label className="required fs-5 fw-bold mb-2">Event Description</label>
                            <Editor
                                className="form-control form-control-solid"
                                rows={4}
                                name="Description"
                                id="Description"
                                onEditorStateChange={this.onEditorStateChange}
                                wrapperClassName="form-control form-control-solid"
                                editorClassName="form-control form-control-solid"
                                editorState={editorState}
                            />

                        </div>

                        <button type="submit" id="kt_modal_new_address_submit" data-kt-indicator={this.props.isLoading} className="btn btn-primary w-25 offset-sm-4 mb-9 mt-9">
                            <span className="indicator-label">Submit</span>
                            <span className="indicator-progress">Please wait...
                                <span className="spinner-border spinner-border-sm align-middle ms-2" />
                            </span>
                        </button>
                    </div>
                </div>
            </form>
        );
    }
}

export default EventForm;